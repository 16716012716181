import React from 'react'
import { CardText, CardTitle, UncontrolledAlert } from 'reactstrap'

// eslint-disable-next-line
import { StepText } from '@digitalworkflow/dwtranslateclient'
import style from './index.module.scss'
import './TextWithLabel.scss'

const HtmlToReactParser = require('html-to-react').Parser
const htmlToReactParser = new HtmlToReactParser()
/**
 * Props for [[`PageText`]] component
 */
export interface PageTextProps {
  /** Step data. */
  step: StepText
  /** Wrapper type */
  wrapper: string
  /** Text string */
  text: string
  /** Parsed option1 string */
  option1Text: string
  /** Parsed option2 string */
  option2Text: string
}

/**
 * PageText component
 *
 * @component PageText
 * @category PageElements
 */
const PageText = ({ step, option1Text, option2Text }: PageTextProps) => {
  // Allow for additional Bootstrap modifiers
  const additionalClassNames: string[] = []

  /*  htmlToReactParser didn't convert \n into <br /> that's why I replace \n with <br />   */
  option1Text = option1Text.replace(/\n/g, '<br />')

  /* Changes to background color */
  if (step.options.checkOption('Info')) additionalClassNames.push('bg-info text-light pl-2')
  if (step.options.checkOption('Primary')) additionalClassNames.push('bg-primary text-light pl-2')
  if (step.options.checkOption('dark')) additionalClassNames.push('bg-dark text-light pl-2')

  /* Highlight the current row, meant to be used with Row */
  if (step.options.checkOption('highlight')) additionalClassNames.push('bd-highlight pl-2')

  /* Add borders to the element */
  if (step.options.checkOption('borderprimary')) additionalClassNames.push('border border-primary')
  if (step.options.checkOption('borderseconday')) additionalClassNames.push('border border-secondary')
  if (step.options.checkOption('bordersuccess')) additionalClassNames.push('border border-success')
  if (step.options.checkOption('borderdanger')) additionalClassNames.push('border border-danger')
  if (step.options.checkOption('borderwarning')) additionalClassNames.push('border border-warning')
  if (step.options.checkOption('BorderInfo')) additionalClassNames.push('border border-info')
  if (step.options.checkOption('BorderLight')) additionalClassNames.push('border border-light')
  if (step.options.checkOption('BorderDark')) additionalClassNames.push('border border-dark')

  /** Add padding and margin options */
  if (step.options.checkOption('p1')) additionalClassNames.push('p1 p-1')
  if (step.options.checkOption('p2')) additionalClassNames.push('p2 p-2')
  if (step.options.checkOption('p3')) additionalClassNames.push('p3 p-3')
  if (step.options.checkOption('p4')) additionalClassNames.push('p4 p-4')

  if (step.options.checkOption('mb1')) additionalClassNames.push('mb1 mb-1')
  if (step.options.checkOption('mb2')) additionalClassNames.push('mb2 mb-2')
  if (step.options.checkOption('mb3')) additionalClassNames.push('mb3 mb-3')
  if (step.options.checkOption('mb4')) additionalClassNames.push('mb4 mb-4')

  if (step.options.checkOption('mt1')) additionalClassNames.push('mt1 mt-1')
  if (step.options.checkOption('mt2')) additionalClassNames.push('mt2 mt-2')
  if (step.options.checkOption('mt3')) additionalClassNames.push('mt3 mt-3')
  if (step.options.checkOption('mt4')) additionalClassNames.push('mt4 mt-4')

  /** Codify the additional options to add to other text */
  const additionalClasses: string = additionalClassNames.join(' ')

  if (step.options.checkOption('SectionTitle'))
    return <div className={`mt-1 mb-0 card-title textSectionTitle ${additionalClasses}`}>{step.text}</div>
  if (step.options.checkOption('Title')) return <h4 className={`${style.title} ${additionalClasses}`}>{step.text}</h4>

  if (step.options.checkOption('SubTitle'))
    return <h5 className={`${style.subtitle} ${additionalClasses}`}>{step.text}</h5>

  if (step.options.checkOption('BlueTitle'))
    return (
      <div style={{ fontSize: '1.2em', color: '#000CCC' }} className={`${additionalClasses}`}>
        {step.text}
      </div>
    )

  if (step.options.checkOption('Hint'))
    return <div className={`mt-0 mb-1 text-info ${additionalClasses}`}>{step.text}</div>

  if (step.options.checkOption('HintLine'))
    return <div className={`mt-2 mb-0 text-info border-bottom ${additionalClasses}`}>{step.text}</div>

  if (step.options.checkOption('Footer')) {
    // this.context?.setFooterText(step.text)
    return <></>
  }

  if (step.options.checkOption('CardTitle'))
    return <CardTitle className={`mt-0 ${additionalClasses}`}>{step.text}</CardTitle>

  if (step.options.checkOption('CardText')) return <CardText className={`${additionalClasses}`}>{step.text}</CardText>

  if (step.options.checkOption('TaskTitle'))
    return <h5 className={`text-truncate font-size-18 ${additionalClasses}`}>{step.text}</h5>

  if (step.options.checkOption('TaskIcon'))
    return (
      <span>
        <i className={step.text} />
      </span>
    )

  if (step.options.checkOption('TaskSubTitle'))
    return <p className={`text-muted mb-0 ${additionalClasses}`}>{step.text}</p>

  if (step.options.checkOption('TaskStatus')) return <span>Status : {step.text}</span>

  if (step.options.checkOption('AlertDanger'))
    return (
      <UncontrolledAlert color='danger' role='alert'>
        <span className={additionalClasses}>{step.text}</span>
      </UncontrolledAlert>
    )

  if (step.options.checkOption('AlertSuccess'))
    return (
      <UncontrolledAlert color='success' role='alert'>
        <span className={additionalClasses}>{step.text}</span>
      </UncontrolledAlert>
    )

  if (step.options.checkOption('AlertPrimary'))
    return (
      <UncontrolledAlert color='primary' role='alert'>
        <span className={additionalClasses}>{step.text}</span>
      </UncontrolledAlert>
    )

  if (step.options.checkOption('AlertWarning'))
    return (
      <UncontrolledAlert color='warning' role='alert'>
        <span className={additionalClasses}>{step.text}</span>
      </UncontrolledAlert>
    )

  if (step.options.checkOption('BulletItem'))
    return (
      <p className='text-muted'>
        <i className='fa fa-caret-right font-size-16 align-middle text-primary me-2'></i>
        {step.text}
      </p>
    )

  if (step.options.checkOption('Row'))
    // Brian: This option allows a type of table to be created for showing admin data
    // option_1 and option_2 are put into columns that can align.
    return (
      <div className={`d-md-flex flex-row ${additionalClasses}`}>
        <div className='pt-2'> {step.text} </div>
      </div>
    )

  if (step.options.checkOption('WithLabel')) {
    // Brian: This option allows a type of table to be created for showing admin data
    // option_1 and option_2 are put into columns that can align.
    if (option2Text && option2Text.length > 0) {
      return (
        <div className={`textWithLabelRow textWithLabelRowDouble ${additionalClasses}`}>
          <div className='labelPart'> {htmlToReactParser.parse(step.text)} </div>
          <div className='dataPart1'> {htmlToReactParser.parse(option1Text)} </div>
          <div className='dataPart2'> {htmlToReactParser.parse(option2Text)} </div>
        </div>
      )
    }

    if (!option1Text || option1Text.length === 0 || option1Text === 'undefined') option1Text = ''

    // option1Text = `<p>this is a test value </p>`

    return (
      <div className={`textWithLabelRow ${additionalClasses}`}>
        <div className='labelPart'> {htmlToReactParser.parse(step.text)} </div>
        <div className='dataPart1'> {htmlToReactParser.parse(option1Text)} </div>
      </div>
    )
  }

  return <div className={additionalClassNames + ' ' + style.stdtext}>{htmlToReactParser.parse(step.text)}</div>
}

export default PageText
