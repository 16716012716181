import React, { useCallback } from 'react'
import './TaskListFilterCard.scss'
import { StepButton } from '@digitalworkflow/dwtranslateclient'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store'

interface ITaskListFilterCard {
  step: StepButton
}

const TaskListFilterCard = ({ step }: ITaskListFilterCard) => {
  // const dispatch = useDispatch()

  const sname = 'todo'
  const activeFilters = useSelector((state: RootState) => state.tasks[sname].activeFilters)

  /**
   *  Brian: I commented out this code because we are not using TaskFilterCard right now and it wasn't coded right
   */
  const handleClick = useCallback(() => {
    // if (activeFilters.includes(step.key_id)) {
    //   const newList = activeFilters.filter((item) => item !== step.key_id)
    //   dispatch(updateActiveFilters({ sname: 'todo', filters: newList }))
    //   dispatch(updateActiveFilters({ sname: 'dashboard', filters: newList }))
    // } else {
    //   const newList = [...activeFilters, step.key_id]
    //   dispatch(updateActiveFilters({ sname: 'todo', filters: newList }))
    //   dispatch(updateActiveFilters({ sname: 'dashboard', filters: newList }))
    // }
  }, [step, activeFilters])

  return (
    <div className='tasklist-filter-card-container' onClick={handleClick}>
      <div className='task-state'>
        <div className='task-step'>
          <p className='task-text'>{step.text}</p>
          <p className='task-count'>{0}</p>
        </div>
      </div>
    </div>
  )
}
export default TaskListFilterCard
