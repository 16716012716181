import { AuthService } from '@digitalworkflow/dwloginclient'
import { PageEngine, PageManager } from '@digitalworkflow/dwtranslateclient'
import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { Container, Spinner } from 'reactstrap'
import Breadcrumbs from '../../components/Breadcrumns/Breadcrumbs'
import { RenderHelper } from '../../components/RenderHelper'
import SyncModal from '../../components/SyncModal'
import { projectName } from '../../constants'

import { useData } from '../../context/DataContext'
import { BreadcrumbItem } from '../../types/breadcrumbs'
import { LocalSettings } from '../../utils/LocalSettings'
import { useForceUpdateData } from '../../utils/hooks/useForceUpdateData'

const authService = new AuthService()

const SurveyLoader = () => {
  const location = useLocation()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [breadcrumbItems, setBreadcrumbItems] = useState<BreadcrumbItem[]>([])
  const [allComponents, setAllComponents] = useState<Array<JSX.Element>>([])
  const { setLogicValues, setStepTraceItems, setFormTraceItems } = useData()
  const { magicKey } = useParams()
  const { syncing, width, forceUpdate } = useForceUpdateData()
  /** Update the logic values for Trace functions
  TODO: This is not really the best way, we should load these values
  once the trace button is pressed and we need to account for sub member values.
  */
  const setPageLogicValues = (rend_helper: RenderHelper) => {
    const json = rend_helper.logicRef?.values.toJSON()
    if (json) {
      setLogicValues(json)
    }
  }

  const pageLoad = async () => {
    AuthService.setProjectName(projectName)
    setLoading(true)
    const page: PageEngine = await PageManager.instance().findRoute(location.pathname)

    console.log(page)

    if (page && page.isValid()) {
      setStepTraceItems([])
      setFormTraceItems([])
      const rend_helper = new RenderHelper()
      rend_helper.breadcrumbItems = breadcrumbItems
      await page.processPage(rend_helper)

      const list: JSX.Element[] = []
      const breadcrumbs: BreadcrumbItem[] = []

      rend_helper.currentTarget.forEach((e) => {
        list.push(e as JSX.Element)
      })
      setAllComponents(list)

      rend_helper.breadcrumbItems.forEach((e) => {
        breadcrumbs.push(e)
      })

      setPageLogicValues(rend_helper)

      setBreadcrumbItems(breadcrumbs)
    }
    setLoading(false)
  }

  useEffect(() => {
    const loginWithMagicKeyAndSyncDB = async (key: string) => {
      const authResult = await authService.authenticateByKey(key)
      if (authResult && authResult.is_error === false) {
        setError(false)
        LocalSettings.setPortalAuthToken(`${authResult.data.authToken}`)
        await forceUpdate()
      } else {
        //  if invalid token lets show a message that the magic key is invalid
        setError(true)
      }
    }
    if (magicKey) {
      loginWithMagicKeyAndSyncDB(magicKey)
    }
  }, [])

  useEffect(() => {
    if (!syncing) {
      pageLoad()
    }
  }, [syncing, location.pathname])

  if (error) {
    return <h1 className='m-5'>Invalid Magic Token</h1>
  }

  return (
    <Container fluid className='survey-container'>
      <Breadcrumbs breadcrumbItems={breadcrumbItems} />
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'calc(100vh - 80px)' }}>
          <Spinner color='primary' />
        </div>
      ) : (
        <div className='row p-2'>{allComponents}</div>
      )}

      <SyncModal width={width} isOpen={syncing} />
      <div className='endBlock'>&nbsp;</div>
    </Container>
  )
}

export default SurveyLoader
