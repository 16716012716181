/* eslint-disable no-unused-vars */
// Core React & UI Libraries
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useMemo, useState } from 'react'
import Drawer from 'react-modern-drawer'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { PlacesType, Tooltip } from 'react-tooltip'

// Digital Workflow Libraries
import { MenuItem } from '@digitalworkflow/dwreactcommon'
import {
  DataTools,
  FindTaskResults,
  LogicEngine,
  StepTaskList,
  TaskCollection
} from '@digitalworkflow/dwtranslateclient'

// Local Components & Utils
import { useMenu } from '../../../context/MenuContext'
import { RootState } from '../../../redux/store'
import { GlobalHashHelper } from '../../../utils/HashHelper'
import { LocalSettings } from '../../../utils/LocalSettings'
import { MuteTaskModal } from '../../MuteTaskModal'
import { RenderHelper } from '../../RenderHelper'

// Task Related Components
import TakeAssignTask from './TakeAssignTask'
import TaskCountBadge, { pluralize } from './TaskCountBadge'
import TaskHistory from './TaskHistory'
import { TaskListButtonContextMenu } from './TaskListButtonContextMenu'
import TaskListCards from './TaskListCards'
import TaskListDebug from './TaskListDebug'
import { getTaskListSettings } from './TaskListSettings'
import TaskListTable from './TaskListTable'
import TaskReassign from './TaskReassign'

// Styles
import 'react-modern-drawer/dist/index.css'
import 'react-tooltip/dist/react-tooltip.css'

import { updateActiveFilters } from '../../../redux/slices/tasklistSlice'
import './TaskList.scss'
import { checkIsUserAuthorized } from './TaskListHelper'
import TaskListFilters from './TaskListFilters'
import { updateSearchTerm } from '../../../redux/slices/settingsSlice'
import { TaskStatusCode } from '@digitalworkflow/dwtranslateclient/lib/Models/Task/TaskSchema'

interface TaskListProps {
  step: StepTaskList
  renderer: RenderHelper
  logic: LogicEngine | undefined
}

type DisplayModeType = 'list' | 'table'

interface TaskState {
  displayMode: DisplayModeType
  subtaskList?: FindTaskResults[]
}

interface ModalState {
  showHistoryForTask?: FindTaskResults
  showTakeModel?: FindTaskResults
  reassignTask?: FindTaskResults
  contextTask?: FindTaskResults
  showMuteTaskModal: boolean
  selectedTask?: FindTaskResults
  subShowingTask?: FindTaskResults
}

interface MenuState {
  showing: boolean
  position: { x: number; y: number }
  contextPosition: { x: number; y: number }
}

const TaskList = ({ step, logic }: TaskListProps) => {
  // Context & Redux
  const { menuItems, updateMenuItems } = useMenu()

  const [filteredTaskList, setFilteredTaskList] = useState<FindTaskResults[]>([])

  // State Management
  const [taskState, setTaskState] = useState<TaskState>({
    displayMode: LocalSettings.taskDisplayMode as DisplayModeType
  })

  const currentRoute = useSelector((state: RootState) => state.settings.currentRoute)

  const currentUserName = useSelector((state: RootState) => state.settings.currentUsername)

  const dispatch = useDispatch()

  // Reference to the Redux store with all task lists
  const storeTasks = useSelector((state: RootState) => {
    if (step.options.checkOption('dashboard')) {
      return state.tasks.dashboard
    } else if (step.options.checkOption('Search')) {
      return state.tasks.search
    } else {
      return state.tasks.todo
    }
  })

  const [modalState, setModalState] = useState<ModalState>({
    showHistoryForTask: undefined,
    showTakeModel: undefined,
    reassignTask: undefined,
    contextTask: undefined,
    showMuteTaskModal: false,
    selectedTask: undefined,
    subShowingTask: undefined
  })

  const [menuState, setMenuState] = useState<MenuState>({
    showing: false,
    position: { x: 0, y: 0 },
    contextPosition: { x: 0, y: 0 }
  })

  const [filterMenuShowing, setFilterMenuShowing] = useState(false)

  const isDashboard = () => step.options.checkOption('dashboard')
  const isSearch = () => step.options.checkOption('Search')
  const hasManageAccess = () => false

  useEffect(() => {
    const handleClick = () => setModalState((prev) => ({ ...prev, contextTask: undefined }))
    window.addEventListener('click', handleClick)
    return () => window.removeEventListener('click', handleClick)
  }, [])

  const createSubMenuItems = (list: any): any => {
    // Update menu items
    const uniqueProjectIds = new Set(list.map((item: any) => item.project_id))
    return Array.from(uniqueProjectIds).map((projectId) => {
      const project_name = list.find((item: { project_id: string }) => item.project_id === projectId)?.project_name
      return {
        item_type: 'Link',
        subtitle_en: project_name,
        tooltip_en: project_name,
        route: location.pathname.includes('/page/dashboard')
          ? `/page/dashboard#type=${projectId}`
          : `/page/tasks#type=${projectId}`,
        view_groups: 'PI, PSI'
      }
    })
  }

  const updateMenuChildren = (menuItemChildren: MenuItem[], pathname: string) => {
    const updatedMenuItems = menuItems.map((item) => {
      if (item.route?.includes(pathname)) {
        return {
          ...item,
          item_type: menuItemChildren.length > 1 ? 'Menu' : 'Link',
          children: menuItemChildren
        }
      } else return item
    })
    updateMenuItems(updatedMenuItems)
  }

  useEffect(() => {
    // Update menu items
    const menuItemChildren = createSubMenuItems(storeTasks.taskList)
    updateMenuChildren(menuItemChildren ?? [], location.pathname)
  }, [storeTasks.taskList])

  const handleMoreOptionsMenu = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    setFilterMenuShowing(!filterMenuShowing)

    // const iconElement = e.currentTarget
    // const wrapperElement = iconElement.closest('.task_list_wrapper-container')

    // if (wrapperElement) {
    //   const wrapperRect = wrapperElement.getBoundingClientRect()
    //   const iconRect = iconElement.getBoundingClientRect()

    //   setMenuState((prev) => ({
    //     ...prev,
    //     showing: !prev.showing,
    //     position: {
    //       x: iconRect.left - wrapperRect.left - 330,
    //       y: iconRect.top - wrapperRect.top + 32
    //     }
    //   }))
    // }
  }

  const handleManageButton = () => {
    setTaskState((prev) => ({ ...prev, taskList: [] }))
    step.processSearch('Admin,Complete,Drafts,OnHold,Cancelled')
  }

  const handleRefreshButton = () => {
    setTaskState((prev) => ({ ...prev, taskList: [] }))
    step.processSearch()
  }

  const handleTaskActions = {
    onShowHistory: (taskId: string) => {
      const task = storeTasks.allTasks.find((item) => item.task_id === taskId)
      if (task) {
        setModalState((prev) => ({ ...prev, showHistoryForTask: task }))
      }
    },

    onShowReassignModal: (taskId: string) => {
      const task = storeTasks.allTasks.find((item) => item.task_id === taskId)
      if (task) {
        setModalState((prev) => ({ ...prev, reassignTask: task }))
      }
    },

    onShowTakeModel: (taskId: string) => {
      const task = storeTasks.allTasks.find((item) => item.task_id === taskId)
      if (task) {
        setModalState((prev) => ({ ...prev, showTakeModel: task }))
      }
    },

    handleMuteTask: async (muteDuration: number, comment?: string) => {
      if (modalState.selectedTask?.task_id) {
        const task = await TaskCollection.findTaskById(modalState.selectedTask.task_id)
        const muteResponse = await task?.SetMute(true, muteDuration, comment)
        if (muteResponse?.success) {
          toast.success(muteResponse.message)
        } else {
          toast.error(muteResponse?.message)
        }
      }
    }
  }

  const renderTaskBadges = (task: FindTaskResults) => (
    <div className='d-flex'>
      <TaskCountBadge
        count={task.subtask_status_count.taskInProgress}
        color='#4cb820'
        tooltip={`${pluralize.subtask(task.subtask_status_count.taskInProgress)} waiting to be completed`}
      />
      <TaskCountBadge
        count={task.subtask_status_count.taskClosed}
        color='#030303'
        tooltip={`${pluralize.subtask(task.subtask_status_count.taskClosed)} already reviewed/closed`}
      />
      <TaskCountBadge
        count={task.subtask_status_count.taskRejected}
        color='#d2494c'
        tooltip={`${pluralize.subtask(task.subtask_status_count.taskRejected)} rejected/expired/cancelled`}
      />
    </div>
  )

  const backToParent = (parent_id: string) => {
    const parentTask = storeTasks.allTasks.filter((task) => task.task_id === parent_id)
    if (parentTask.length > 0) {
      setModalState((prev) => ({
        ...prev,
        subShowingTask: parentTask[0]
      }))
      setTaskState((prev) => ({
        ...prev,
        subtaskList: storeTasks.allTasks.filter((task) => parentTask[0]?.subtask_ids?.includes(task.task_id))
      }))
    } else {
      setModalState((prev) => ({
        ...prev,
        subShowingTask: undefined
      }))
    }
  }

  // Add useEffect for subtask list updates
  useEffect(() => {
    if (modalState.subShowingTask) {
      setTaskState((prev) => ({
        ...prev,
        subtaskList: storeTasks.allTasks.filter((task) =>
          modalState.subShowingTask?.subtask_ids?.includes(task.task_id)
        )
      }))
    } else {
      setTaskState((prev) => ({
        ...prev,
        subtaskList: []
      }))
    }
  }, [modalState.subShowingTask])

  const categories = useMemo(() => {
    const allCategories = storeTasks.allTasks.map((task) => task.dash_category)

    // Remove duplicates
    const uniqueCategories = allCategories.filter((value, index, self) => self.indexOf(value) === index)

    // Sort by settings order
    return uniqueCategories.sort((a, b) => getTaskListSettings(a).order - getTaskListSettings(b).order)
  }, [storeTasks.allTasks])

  const getSname = () => {
    if (step.options.checkOption('dashboard')) {
      return 'dashboard'
    } else if (step.options.checkOption('Search')) {
      return 'search'
    } else {
      return 'todo'
    }
  }

  useEffect(() => {
    if (isSearch()) {
      dispatch(updateSearchTerm(step.text))
    }

    const filterText = DataTools.internalValidateString(step.option_1 || '')
    if (filterText !== '' && step.logicRef) {
      step.logicRef.processTextReplacement(filterText).then((resultFilterText) => {
        /** Parse any configuration settings */
        console.log('UPDATING ACTIVE FILTERS', getSname(), resultFilterText)
        dispatch(updateActiveFilters({ sname: getSname(), filters: resultFilterText }))
      })
    } else {
      dispatch(updateActiveFilters({ sname: getSname(), filters: '' }))
    }
  }, [])

  /**
   * This code applies the active Filters which come from the configuration in lua
   * by updating the redux store version of the tasks with the active filters
   */
  useEffect(() => {
    // Filter by project type if specified in route
    const filteredTasks: FindTaskResults[] = storeTasks.taskList.filter((task) => {
      if (currentRoute?.includes('type=')) {
        const taskType = currentRoute.split('type=').pop()
        if (taskType && typeof taskType === 'string' && task.project_id !== taskType) {
          return false
        }
      }

      return true
    })

    setFilteredTaskList(
      filteredTasks.filter((task) => {
        for (const key of Object.keys(task)) {
          if (storeTasks.activeFilters[key]) {
            const re = new RegExp(storeTasks.activeFilters[key], 'i')
            if (!re.test((task as any)[key])) {
              return false
            }
          }
        }

        for (const key of task.data_fields || []) {
          const keyname = key.label.toLowerCase().replace(' ', '_')
          if (storeTasks.activeFilters[keyname]) {
            const re = new RegExp(storeTasks.activeFilters[keyname], 'i')
            if (!re.test(DataTools.internalValidateString(key.value))) {
              return false
            }
          }
        }

        return true
      })
    )
  }, [storeTasks.taskList, storeTasks.activeFilters, currentRoute])

  return (
    <div className='task_list_wrapper-container'>
      {GlobalHashHelper.hasOption('debug') && <TaskListDebug items={storeTasks.taskList} />}
      <Tooltip id='task-list-tooltip' place={'top-end' as PlacesType} />
      <div className='task_list_wrapper'>
        <div className='title'>
          {step.getTitle()} {storeTasks.loadingStatus}
        </div>

        {hasManageAccess() && (
          <>
            <div className='icon-manage' data-testid='icon-manage' onClick={handleManageButton}>
              Manage
            </div>
            <div className='iconBreak' />
          </>
        )}

        {
          /** Refresh Icon removed by Brian - Tasks List should auto refresh now */
          false && (
            <div className='icon' data-testid='reload' onClick={handleRefreshButton}>
              <FontAwesomeIcon icon={['fas', 'arrows-rotate']} />
            </div>
          )
        }

        <div className='iconBreak' />

        <div
          className='icon'
          data-testid='icon-list'
          onClick={() => {
            setTaskState((prev) => ({ ...prev, displayMode: 'list' }))
            LocalSettings.taskDisplayMode = 'list'
          }}
        >
          <FontAwesomeIcon icon={['fas', 'braille']} color={taskState.displayMode === 'list' ? '#00a6b6' : '#253746'} />
        </div>

        <div
          className='icon'
          data-testid='icon-table'
          onClick={() => {
            setTaskState((prev) => ({ ...prev, displayMode: 'table' }))
            LocalSettings.taskDisplayMode = 'table'
          }}
        >
          <FontAwesomeIcon icon={['fas', 'table']} color={taskState.displayMode === 'table' ? '#00a6b6' : '#253746'} />
        </div>

        <div className='iconBreak' />

        <div className='icon_filters' data-testid='icon-options' onClick={handleMoreOptionsMenu}>
          <FontAwesomeIcon icon={['fal', 'filters']} color='#253746' />
          <FontAwesomeIcon icon={filterMenuShowing ? ['fas', 'caret-right'] : ['fas', 'caret-down']} color='#253746' />
        </div>
      </div>

      {filterMenuShowing ? <TaskListFilters sname={getSname()} /> : <div className='filterMenuPlaceholder' />}

      {taskState.displayMode === 'list' && (
        <TaskListCards
          items={filteredTaskList}
          logicRef={step.logicRef}
          setSubShowingTask={(task) => setModalState((prev) => ({ ...prev, subShowingTask: task }))}
          renderTaskBadges={renderTaskBadges}
          categories={categories}
          setContextTask={(task) => setModalState((prev) => ({ ...prev, contextTask: task }))}
          setContextMenuPosition={(pos) => setMenuState((prev) => ({ ...prev, contextPosition: pos }))}
        />
      )}

      {taskState.displayMode === 'table' && (
        <TaskListTable
          options={step.options}
          items={filteredTaskList}
          logicRef={step.logicRef}
          onShowHistory={handleTaskActions.onShowHistory}
          currentUserName={currentUserName}
          onShowTakeModel={handleTaskActions.onShowTakeModel}
          onShowReassignModal={handleTaskActions.onShowReassignModal}
          setSubShowingTask={(task) => setModalState((prev) => ({ ...prev, subShowingTask: task }))}
          taskLoading={!storeTasks.loaded}
          renderTaskBadges={renderTaskBadges}
          subShowingTask={modalState.subShowingTask !== undefined}
        />
      )}

      {modalState.contextTask && (
        <TaskListButtonContextMenu top={menuState.contextPosition.y} left={menuState.contextPosition.x}>
          <ul>
            {!modalState.subShowingTask &&
              modalState.contextTask.auser !== currentUserName &&
              modalState.contextTask.task_status_id !== TaskStatusCode.TaskClosed &&
              modalState.contextTask.task_status_id !== TaskStatusCode.TaskRejected &&
              modalState.contextTask.task_status_id !== TaskStatusCode.TaskCancelled &&
              !isDashboard() && (
                <li onClick={() => handleTaskActions.onShowTakeModel(modalState.contextTask!.task_id)}>Take</li>
              )}
            <li onClick={() => handleTaskActions.onShowHistory(modalState.contextTask!.task_id)}>History of task</li>
            {modalState.contextTask.can_manage && (
              <li onClick={() => handleTaskActions.onShowReassignModal(modalState.contextTask!.task_id)}>
                Reassign task
              </li>
            )}
            {modalState.contextTask.task_status === 'InProgress' &&
              checkIsUserAuthorized(
                modalState.contextTask.auser,
                modalState.contextTask.create_by,
                modalState.contextTask.can_manage
              ) && (
                <li
                  onClick={() =>
                    setModalState((prev) => ({
                      ...prev,
                      selectedTask: modalState.contextTask,
                      showMuteTaskModal: true
                    }))
                  }
                >
                  Mute task
                </li>
              )}
          </ul>
        </TaskListButtonContextMenu>
      )}

      {modalState.showHistoryForTask && (
        <TaskHistory
          item={modalState.showHistoryForTask}
          onClose={() => setModalState((prev) => ({ ...prev, showHistoryForTask: undefined }))}
        />
      )}

      {modalState.reassignTask && (
        <TaskReassign
          item={modalState.reassignTask}
          logic={logic}
          onClose={() => setModalState((prev) => ({ ...prev, reassignTask: undefined }))}
        />
      )}

      {modalState.showTakeModel && (
        <TakeAssignTask
          item={modalState.showTakeModel}
          currentUsername={currentUserName}
          logic={logic}
          onClose={() => setModalState((prev) => ({ ...prev, showTakeModel: undefined }))}
        />
      )}

      <div
        className={'dropdown-menu dropdown-menu-sm ' + (menuState.showing ? 'dropdown-showing' : 'dropdown-hidden')}
        style={{ top: menuState.position.y, left: menuState.position.x, display: 'block', zIndex: 9999 }}
        onClick={() => setMenuState((prev) => ({ ...prev, showing: false }))}
        id='context-menu'
      ></div>

      <MuteTaskModal
        showMuteTaskModal={modalState.showMuteTaskModal}
        onCloseModal={() => setModalState((prev) => ({ ...prev, showMuteTaskModal: false }))}
        handleMuteTask={handleTaskActions.handleMuteTask}
      />

      <Drawer
        open={modalState.subShowingTask !== undefined}
        onClose={() =>
          backToParent(
            modalState.subShowingTask && modalState.subShowingTask.parent_id ? modalState.subShowingTask.parent_id : ''
          )
        }
        direction='right'
        size='60vw'
      >
        <div className='slide_task_list_wrapper'>
          <div className='title'>
            <FontAwesomeIcon
              icon={['fas', 'arrow-left']}
              onClick={() =>
                backToParent(
                  modalState.subShowingTask && modalState.subShowingTask.parent_id
                    ? modalState.subShowingTask.parent_id
                    : ''
                )
              }
              style={{ cursor: 'pointer' }}
            />{' '}
            Subtasks of <b>{modalState.subShowingTask?.task_title}</b>{' '}
          </div>

          {taskState.displayMode === 'list' && (
            <TaskListCards
              items={taskState.subtaskList || []}
              logicRef={step.logicRef}
              setSubShowingTask={(task) => setModalState((prev) => ({ ...prev, subShowingTask: task }))}
              renderTaskBadges={renderTaskBadges}
              categories={categories}
              setContextTask={(task) => setModalState((prev) => ({ ...prev, contextTask: task }))}
              setContextMenuPosition={(pos) => setMenuState((prev) => ({ ...prev, contextPosition: pos }))}
            />
          )}
          {taskState.displayMode === 'table' && (
            <TaskListTable
              options={step.options}
              items={taskState.subtaskList || []}
              logicRef={step.logicRef}
              taskLoading={!storeTasks.loaded}
              currentUserName={currentUserName}
              onShowHistory={handleTaskActions.onShowHistory}
              onShowTakeModel={handleTaskActions.onShowTakeModel}
              onShowReassignModal={handleTaskActions.onShowReassignModal}
              subShowingTask={modalState.subShowingTask !== undefined}
              setSubShowingTask={(task) => setModalState((prev) => ({ ...prev, subShowingTask: task }))}
              renderTaskBadges={renderTaskBadges}
            />
          )}
        </div>
      </Drawer>
    </div>
  )
}

export default TaskList
