import './TestAccounts.scss'

import React, { useEffect, useState } from 'react'

import { WorkflowUser } from '@digitalworkflow/dwloginclient'
import { Task, TaskCollection } from '@digitalworkflow/dwtranslateclient'
import { TaskEffectivePermission } from '@digitalworkflow/dwtranslateclient/lib/Models/Task/TaskSchema'

import TaskDebugView from './TaskDebugView'

const CurrentUserProblemFinder = () => {
  const [user, setCurrectUser] = useState<WorkflowUser | undefined>(undefined)
  const [myAssignedTasks, setMyAssignedTasks] = useState<Task[]>([])
  const [showExplainMyTasks, setShowExplainMyTasks] = useState<boolean>(false)
  const [textDebugTask, setTextDebugTask] = useState<string>('')
  const [taskToDebug, setTaskToDebug] = useState<Task | undefined>(undefined)

  useEffect(() => {
    const refresh = async () => {
      console.log('Refreshing test accounts.')
      const user = WorkflowUser.GetCurrentUser()
      setCurrectUser(user)

      const allTask =
        (await TaskCollection.getCollection().findAll({ project_id: { $exists: true }, auser: user?.username })) || []
      const myList = allTask.map((task) => new Task(TaskCollection.getCollection(), task))
      setMyAssignedTasks(myList)
    }

    refresh()
  }, [])

  const explainMyTasks = () => {
    setShowExplainMyTasks(!showExplainMyTasks)
  }

  const showPermission = (list: TaskEffectivePermission[]) => {
    return (
      <td style={{ borderTop: '1px solid black', verticalAlign: 'top' }}>
        <ul>
          {list.length === 0 && <li> No Permission Set </li>}
          {list.map((perm) => {
            return <li key={perm.id}> {perm.id}</li>
          })}
        </ul>
      </td>
    )
  }

  const showExplainedTasks = () => {
    return (
      <table style={{ width: '100%' }}>
        <tbody>
          {myAssignedTasks.map((task) => {
            return (
              <tr key={task.id}>
                <td style={{ borderTop: '1px solid black', width: 120, verticalAlign: 'top' }}>{task.data.id}</td>
                <td style={{ borderTop: '1px solid black', width: 180, verticalAlign: 'top' }}>{task.data.title}</td>
                {showPermission(task.data.eff_perm || [])}
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }

  const showTaskDebug = () => {
    return <TaskDebugView task={taskToDebug} />
  }

  const generateProblems = () => {
    return (
      <div className='debugDiv'>
        Total Assigned Tasks: {myAssignedTasks.length} <br />
        <button onClick={() => explainMyTasks()}>Explain why</button>
        {showExplainMyTasks && showExplainedTasks()}
        <br />
        <br />
        Debug Task:
        <input
          type='text'
          style={{ width: 120, paddingLeft: 10, marginLeft: 10 }}
          onChange={(e) => setTextDebugTask(e.target.value)}
        ></input>
        <button
          onClick={() => {
            console.log('Debug Task: ', textDebugTask)
            TaskCollection.findTaskById(textDebugTask, false).then((task) => {
              console.log('Debugging task by ID:', task)
              setTaskToDebug(task)
            })
          }}
        >
          Search
        </button>
        {showTaskDebug()}
      </div>
    )
  }

  const generateCurrentUser = () => {
    return (
      <div className='currentUserTableWrapper'>
        {user ? (
          <table className='table currentUserTable'>
            <thead></thead>
            <tbody>
              <tr>
                <td>Current Username:</td>
                <td> {user.username} </td>
              </tr>
              <tr>
                <td>Current Workgroups:</td>
                <td>{user.getWorkgroups().join(', ')}</td>
              </tr>
            </tbody>
          </table>
        ) : (
          <></>
        )}
        {generateProblems()}
      </div>
    )
  }

  return <div>{generateCurrentUser()}</div>
}

export default CurrentUserProblemFinder
