import { DataTools, FindTaskResults, StringOptions } from '@digitalworkflow/dwtranslateclient'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type ColumnFilterConfig = {
  [columnName: string]: string | RegExp
}

export type TaskListCounters = {
  totalMuted: number
  totalRejected: number
  totalClosed: number
  totalOther: number
}

export type TaskListSliceState = {
  /** Task List that has been filtered by options */
  taskList: FindTaskResults[]

  /** Task list received by StepTaskList */
  allTasks: FindTaskResults[]

  loaded: boolean

  activeFilters: ColumnFilterConfig

  loadingStatus: string

  /** Updated when the task list is filtered with stats about the tasks */
  counters: TaskListCounters
}

export type TaskListSliceType = 'todo' | 'dashboard' | 'search'

const initialState: Record<TaskListSliceType, TaskListSliceState> = {
  todo: {
    taskList: [],
    allTasks: [],
    loaded: false,
    activeFilters: {},
    loadingStatus: '(Initializing)',
    counters: { totalMuted: 0, totalRejected: 0, totalClosed: 0, totalOther: 0 }
  },
  dashboard: {
    taskList: [],
    allTasks: [],
    loaded: false,
    activeFilters: {},
    loadingStatus: '(Initializing)',
    counters: { totalMuted: 0, totalRejected: 0, totalClosed: 0, totalOther: 0 }
  },
  search: {
    taskList: [],
    allTasks: [],
    loaded: false,
    activeFilters: {},
    loadingStatus: '(Initializing)',
    counters: { totalMuted: 0, totalRejected: 0, totalClosed: 0, totalOther: 0 }
  }
}

type UpdatePayload = { sname: TaskListSliceType; list: FindTaskResults[] }
type UpdateLoadedPayload = { sname: TaskListSliceType; isLoaded: boolean }

const tasklistSlice = createSlice({
  name: 'players',
  initialState,
  reducers: {
    updateActiveFilters: (state, action: PayloadAction<{ sname: TaskListSliceType; filters: string }>) => {
      const opt: ColumnFilterConfig = {}
      const filters = new StringOptions(action.payload.filters)
      for (const key of filters.Keys()) {
        opt[key] = DataTools.internalValidateString(filters.getValue(key, ''))
      }

      state[action.payload.sname] = {
        ...state[action.payload.sname],
        activeFilters: opt
      }
    },
    updateTaskListLoaded: (state, action: PayloadAction<UpdateLoadedPayload>) => {
      state[action.payload.sname] = {
        ...state[action.payload.sname],
        loaded: action.payload.isLoaded
      }
    },
    updateTaskList: (state, action: PayloadAction<UpdatePayload>) => {
      state[action.payload.sname] = {
        ...state[action.payload.sname],
        taskList: [...action.payload.list]
      }
    },
    updateAllTaskList: (state, action: PayloadAction<UpdatePayload>) => {
      state[action.payload.sname] = {
        ...state[action.payload.sname],
        allTasks: [...action.payload.list]
      }
    },
    updateTaskListStatus: (state, action: PayloadAction<{ sname: TaskListSliceType; status: string }>) => {
      state[action.payload.sname] = {
        ...state[action.payload.sname],
        loadingStatus: action.payload.status
      }
    },
    updateTaskListCounters: (
      state,
      action: PayloadAction<{ sname: TaskListSliceType; counters: TaskListCounters }>
    ) => {
      state[action.payload.sname] = {
        ...state[action.payload.sname],
        counters: action.payload.counters
      }
    }
  }
})

export const {
  updateTaskList,
  updateAllTaskList,
  updateTaskListLoaded,
  updateActiveFilters,
  updateTaskListStatus,
  updateTaskListCounters
} = tasklistSlice.actions
export default tasklistSlice.reducer
