import {
  toggleShowClosed,
  toggleShowMuted,
  toggleShowOther,
  toggleShowRejected
} from '../../../redux/slices/settingsSlice'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import './TaskListFilters.scss'
import { RootState } from '../../../redux/types'
import { Tooltip } from 'react-tooltip'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'

export interface TaskListFiltersProps {
  sname: string
}

export interface TaskListFiltersButtonProps {
  sname: string
  label: string
  toggleFunction: Function
  count: number
  hint?: string
  active: boolean
}

const TaskListFilterButton: React.FC<TaskListFiltersButtonProps> = ({
  sname,
  label,
  toggleFunction,
  count,
  hint,
  active
}) => {
  const dispatch = useDispatch()

  /** Click on one of the menu functions */
  const handleClickMenu = (toggleFunction: Function) => {
    dispatch(toggleFunction())
  }

  const className = active ? 'filter_active' : ''

  return (
    <div
      key={`tasklist_${sname}_filter_button`}
      className={'tasklist_filter_button ' + className}
      onClick={() => handleClickMenu(toggleFunction)}
      data-tooltip-id={`tooltip-${sname}`}
      data-tooltip-content={hint}
    >
      <div className={'side_bar ' + className}></div>
      <span>
        <FontAwesomeIcon
          color={active ? '#16cc59' : '#000000'}
          icon={active ? faEye : faEyeSlash}
          className={'mr-2 ' + className}
        />
        {label}
      </span>
      <span className={count > 0 ? 'content_badge' : 'content_none'}> {count} </span>
      <Tooltip id={`tooltip-${sname}`} delayShow={2000} />
    </div>
  )
}

const TaskListFilters: React.FC<TaskListFiltersProps> = ({ sname }) => {
  const showClosed = useSelector((state: RootState) => state.settings.showClosed)
  const showMuted = useSelector((state: RootState) => state.settings.showMuted)
  const showOther = useSelector((state: RootState) => state.settings.showOther)
  const showRejected = useSelector((state: RootState) => state.settings.showRejected)

  // Reference to the Redux store with all task lists
  const storeTasks = useSelector((state: RootState) => {
    if (sname === 'todo') return state.tasks.todo
    else if (sname === 'dashboard') return state.tasks.dashboard
    else return state.tasks.search
  })

  return (
    <div key={`tasklist_${sname}_filter`} className='tasklist_filters'>
      <TaskListFilterButton
        sname={sname}
        label='Rejected and Cancelled'
        toggleFunction={toggleShowRejected}
        count={storeTasks.counters.totalRejected}
        active={showRejected}
        hint='Show tasks that have been rejected or cancelled recently.'
      />
      <TaskListFilterButton
        sname={sname}
        label='Muted'
        toggleFunction={toggleShowMuted}
        count={storeTasks.counters.totalMuted}
        active={showMuted}
        hint='Show tasks that have been muted using the Mute function that allows tasks to be hidden for a short amount of time.'
      />
      <TaskListFilterButton
        sname={sname}
        label='Closed'
        toggleFunction={toggleShowClosed}
        count={storeTasks.counters.totalClosed}
        active={showClosed}
        hint='Show a limited number of very recently closed tasks.'
      />

      {sname !== 'dashboard' && (
        <TaskListFilterButton
          sname={sname}
          label='All Group Members'
          toggleFunction={toggleShowOther}
          count={storeTasks.counters.totalOther}
          active={showOther}
          hint='This option will show tasks that you have access to but are assigned to another user in your permission group.'
        />
      )}
    </div>
  )
}

export default TaskListFilters
