import { DataTools, StringOptions } from '@digitalworkflow/dwtranslateclient'

export class StyleHelper {
  styleString: string
  styleOpt: StringOptions
  myClassName: string[]
  isColorOption: boolean
  isSelected: boolean

  constructor(strStyle: string | StringOptions) {
    if (strStyle instanceof StringOptions) {
      this.styleString = strStyle.toString()
    } else {
      this.styleString = DataTools.internalValidateString(strStyle)
    }

    this.isColorOption = false
    this.isSelected = false
    this.myClassName = []
    this.styleOpt = new StringOptions(this.styleString)
    this.processStyle()
  }

  /** Take keywords from the style string to adjust the classes */
  processStyle() {
    const transform = {
      Primary: 'btn-primary',
      Secondary: 'btn-secondary',
      Danger: 'btn-danger',
      Info: 'btn-info',
      Warning: 'btn-warning',
      Light: 'btn-light',
      Dark: 'btn-dark',
      Success: 'btn-success',
      Outline: 'btn-outline',
      Block: 'btn-block'
    }

    for (const key of Object.keys(transform) as Array<keyof typeof transform>) {
      if (this.styleOpt.checkOption(key)) {
        this.myClassName.push(transform[key])
        this.isColorOption = true
      }
    }

    if (this.styleOpt.checkOption('Selected')) {
      this.myClassName.push('selected')
      this.isSelected = true
    }
  }

  /** Get a list of the class names to apply from the style */
  getClassNames() {
    return this.myClassName.join(' ')
  }
}
