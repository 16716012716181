import { getRxStorageIndexedDB } from 'rxdb-premium/plugins/storage-indexeddb'
// import { toast } from 'react-toastify'
import { LocalDatabaseManager } from '@digitalworkflow/dwtranslateclient'
import { AuthService } from '@digitalworkflow/dwloginclient'
import { projectName } from '../constants'
import { LocalSettings } from './LocalSettings'
import { store } from '../redux/store'
import { setDatabaseSynced } from '../redux/slices/settingsSlice'

export type TableRowCount = {
  [index: string]: number
}

// let hasShownToastThisSession = false
// let toastLock = false

/**
Returns once the database is no longer busy.
Internally this will check the busy state 10 times per second on a timer.
*/
async function checkForDatabaseBusy() {
  const myTimer = setInterval(() => {
    console.log('checking for database busy still...')
  }, 5000)

  console.log('Checking to see if database is busy...')
  await LocalDatabaseManager.instance().isBusy()
  clearInterval(myTimer)

  return true
}

export const clearDatabase = async () => {
  try {
    const instance = LocalDatabaseManager.instance()
    return await instance.clearDatabase()
  } catch (error) {
    console.log(error)
    return false
  }
}

export const deleteDatabase = async () => {
  try {
    const req = await indexedDB.databases()

    req.forEach(async (item) => {
      await indexedDB.deleteDatabase(item.name as string)
    })
  } catch (error) {
    console.log(error)
  }
}

// Function to show toast atomically
// const showToastAtomically = () => {
//   if (toastLock) return
//   toastLock = true

//   if (!hasShownToastThisSession) {
//     toast.warn(
//       'Warning: A connection to the server is not available. You may be offline. Some functions may not work as expected'
//     )
//     hasShownToastThisSession = true
//   }

//   setTimeout(() => {
//     toastLock = false
//   }, 100) // Short delay to prevent rapid successive calls
// }

// let isStoppingOrResuming = false

// const stopAndResumeSync = async (
//   instance: LocalDatabaseManager,
//   setWidth: (value: number) => void,
//   setSyncing: (value: boolean) => void,
//   error: any,
//   setupSyncEvents: () => void
// ) => {
//   if (isStoppingOrResuming) return // Ignore if already in process

//   isStoppingOrResuming = true
//   console.error('Replication error:', error)

//   try {
//     console.log('Attempting to stop sync...')
//     await instance.stopSync()
//     setWidth(100)
//     setSyncing(false)

//     showToastAtomically()

//     // Schedule resumeSync to be called after 2 minutes
//     setTimeout(async () => {
//       try {
//         console.log('Attempting to resume sync...')
//         await instance.resumeSync()
//         console.log('Sync resumed successfully')
//         setupSyncEvents() // Re-establish sync events after resuming
//       } catch (resumeError) {
//         console.error('Error resuming sync:', resumeError)
//       } finally {
//         isStoppingOrResuming = false
//       }
//     }, 120000) // 2 minutes delay before resuming
//   } catch (stopError) {
//     console.error('Error stopping sync:', stopError)
//     isStoppingOrResuming = false
//   }
// }
export const syncDatabase = async (setWidth: (value: number) => void, setSyncing: (value: boolean) => void) => {
  // hasShownToastThisSession = false
  AuthService.setProjectName(projectName)
  const instance = LocalDatabaseManager.instance()
  if (instance.db && !instance.db.destroyed) {
    setSyncing(false)
    return
  }

  const token = LocalSettings.getPortalAuthToken() ?? ''
  await LocalDatabaseManager.startDb(getRxStorageIndexedDB(), token)

  const totalCollections = instance.getCollectionsWithReplication()
  let collections: string[] = totalCollections.map((collection) => collection.getCollectionName())

  const setupSyncEvents = () => {
    const syncEvents = instance.evDatabaseSyncProgress()

    syncEvents.colletionSyncComplete$.subscribe((collection: string) => {
      if (collection) {
        collections = collections.filter((c) => c !== collection)
        const width = parseFloat((100 - (collections.length / totalCollections.length) * 100).toFixed(2))
        setWidth(width)
      }
    })

    syncEvents.initialPullComplete$.subscribe(async (isComplete: boolean) => {
      if (isComplete) {
        console.log('Initial pull replication is complete.')
        await checkForDatabaseBusy()
        await instance.isBusy()
        setWidth(100)
        setSyncing(false)
        store.dispatch(setDatabaseSynced(true))
      }
    })

    syncEvents.error$.subscribe(async (error: any) => {
      if (error !== null && error !== undefined) {
        // stopAndResumeSync(instance, setWidth, setSyncing, error, setupSyncEvents)
        console.error('SyncEvents error:', error)
        setWidth(100)
        setSyncing(false)
      }
    })

    return syncEvents
  }

  setupSyncEvents()
}

export const getCollectionsCount = async (): Promise<TableRowCount> => {
  try {
    const instance = LocalDatabaseManager.instance()
    const totalCollections = instance.getCollectionsWithReplication()
    const collections: TableRowCount = {}
    for (let i = 0; i < totalCollections.length; i++) {
      const collection = totalCollections[i]
      const count = await (await (await collection.getRxCollection()).find().exec()).length
      collections[collection.getCollectionName()] = count
    }
    return collections
  } catch (error) {
    console.log(error)
    return {}
  }
}
